import { Button, Grid } from '@mui/material';
import { Box, styled } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';
import { convertUnixTimeToDate, getAuthorUrl } from '../common/ItemTools';
import AuthorCard from './AuthorCard';

const RootForm = styled(Box)({
  padding: '30px',
  width: '100%',
  height: '100%',
});

const GridButtons = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-evenly',
  padding: '10px',
});

function AuthorForm(props) {
  const {
    onSaveAuthor,
    onShareAuthor,
    onCancel,
    onDeleteAuthor,
    author: propsAuthor,
  } = props;
  const author = propsAuthor || {};

  const { privateData, publicData } = author;

  const initialValues = {
    profiles: privateData ? privateData.profiles : [],
    timeCreated: privateData && privateData.timeCreated
      ? convertUnixTimeToDate(privateData.timeCreated)
      : null,
  };

  const onAuthorRead = (a) => {
    const url = getAuthorUrl(a);
    if (url) window.open(url, '_blank');
  };

  return (
    <RootForm>
      <Formik
        initialValues={initialValues}
        validate={() => {
          const errors = {};
          return errors;
        }}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            onSaveAuthor({ id: author.id, profiles: values.profiles });
            setSubmitting(false);
          }, 500);
        }}
      >
        {({
          handleChange, values, setFieldValue, isSubmitting, submitForm,
        }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Form>
              <Grid spacing={3} container>
                {values.profiles.map((d, index) => {
                  const name1 = `profiles.${index}.url`;
                  const name2 = `profiles.${index}.name`;
                  const name3 = `profiles.${index}.username`;
                  const imageUrl = `profiles.${index}.imageUrl`;
                  return (
                    <Grid xs={12} item>
                      <Field
                        component={TextField}
                        label="Name"
                        name={name2}
                        type="text"
                        fullWidth
                        onChange={handleChange}
                      />
                      <Field
                        component={TextField}
                        label="URL"
                        name={name1}
                        type="text"
                        fullWidth
                        onChange={handleChange}
                      />
                      <Field
                        component={TextField}
                        label="Username"
                        name={name3}
                        type="text"
                        fullWidth
                        onChange={handleChange}
                      />
                      <Field
                        component={TextField}
                        label="Image"
                        name={imageUrl}
                        type="text"
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                  );
                })}
                {privateData && publicData && (
                  <>
                    <Grid lg={2} sm={1} xs={false} item />
                    <Grid lg={4} sm={10} xs={12} item>
                      <AuthorCard
                        author={privateData}
                        key={author.id}
                        onAuthorRead={onAuthorRead}
                      />
                    </Grid>
                    <Grid lg={4} sm={10} xs={12} item>
                      <AuthorCard
                        author={publicData}
                        key={author.id}
                        onAuthorRead={onAuthorRead}
                      />
                    </Grid>
                    <Grid lg={2} sm={1} xs={false} item />
                  </>
                )}
                {privateData && !publicData && (
                  <>
                    <Grid lg={4} sm={1} xs={false} item />
                    <Grid lg={4} sm={10} xs={12} item>
                      <AuthorCard
                        author={privateData}
                        key={author.id}
                        onAuthorRead={onAuthorRead}
                      />
                    </Grid>
                    <Grid lg={4} sm={1} xs={false} item />
                  </>
                )}
                {publicData && !privateData && (
                  <>
                    <Grid lg={4} sm={1} xs={false} item />
                    <Grid lg={4} sm={10} xs={12} item>
                      <AuthorCard
                        author={publicData}
                        key={author.id}
                        onAuthorRead={onAuthorRead}
                      />
                    </Grid>
                    <Grid lg={4} sm={1} xs={false} item />
                  </>
                )}
              </Grid>
              <Grid container>
                <Grid lg={4} sm={2} xs={false} item />
                <GridButtons lg={4} sm={8} xs={12} item>
                  <Button
                    onClick={(event) => {
                      event.preventDefault();
                      const newTags = values.profiles.concat([{}]);
                      setFieldValue('profiles', newTags);
                    }}
                  >
                    Add
                  </Button>
                  <Button disabled={isSubmitting} onClick={submitForm}>
                    Save
                  </Button>
                  <Button onClick={() => onShareAuthor(author)}>
                    Share
                  </Button>
                  <Button disabled={isSubmitting} onClick={onCancel}>
                    Cancel
                  </Button>
                  {onDeleteAuthor && (
                  <Button onClick={() => onDeleteAuthor(author)}>
                    Delete
                  </Button>
                  )}
                </GridButtons>
                <Grid lg={4} sm={2} xs={false} item />
              </Grid>
            </Form>
          </LocalizationProvider>
        )}
      </Formik>
    </RootForm>
  );
}

export default AuthorForm;

AuthorForm.defaultProps = {
  onSaveItem: null,
  onDeleteItem: null,
  onItemAddToList: null,
  onItemRemoveFromList: null,
};
